<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center font-c6">
        <img
          :src="item.person.pictureId"
          alt=""
          class="h-10 w-10 rounded-100 mr-3"
          v-if="item.person.pictureId"
        >
        <div class="h-10 w-10 rounded-100 mr-3 bg-black" />
        <div>{{ item.person.lastName }} {{ item.person.firstName }}</div>
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        <span v-if="item.person.phone">{{ item.person.phone }}</span>
        <span v-if="!item.person.phone">-</span>
      </div>

      <div class="w-1/4 ml-4">
        <span v-if="item.person.email"> {{ item.person.email }} </span>
        <span v-if="!item.person.email">-</span>
      </div>

      <div class="w-1/5 ml-4">
        <div v-if="item.sfd">
          {{ item.sfd.name }}
        </div>
      </div>

      <div
        class="w-1/6 ml-4 flex items-center justify-center"
        v-clipboard:copy="item.person.businessCode"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <div>{{ item.person.businessCode }}</div>
        <icon
          :data="icons.copy"
          height="40"
          width="40"
          class="mr-2 cursor-pointer"
          original
        />
      </div>
    </div>
  </div>
</template>

<script>
import user from '../../../assets/icons/ic-clientAv.svg'
import copy from '../../../assets/icons/ic-copy.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        user,
        copy
      },
    }
  },
  methods: {
    onCopy (a) {
      console.log(a)
    },

    onError (a) {
      console.log(a)
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
